import styled from "styled-components";
import { MegaMenuProps } from "./MegaMenu.types";
import React from "react";

import { MegaMenuWithoutType } from "./MegaMenuWithoutType";
import { MegaMenuWithType } from "./MegaMenuWithType";
import { MegaMenuWithTypeColumnUI } from "./MegaMenuWithTypeColumnUI";
import { HeaderTemplates } from "components/HeaderSchema";


const StyledWrapper = styled.div`
  filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.24));
`;
export const MegaMenu: React.FC<MegaMenuProps> = ({
  groups,
  groupWithTypes,
  isShow,
  templates
}) => {
  let Component = groupWithTypes ? (templates && templates === HeaderTemplates.Second) ? MegaMenuWithTypeColumnUI : MegaMenuWithType : MegaMenuWithoutType;

  return (
    <StyledWrapper>
      <Component
        groups={groups}
        groupWithTypes={groupWithTypes}
        isShow={isShow}
      />
    </StyledWrapper>
  );
};

export default MegaMenu;

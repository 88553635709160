export const transitionFunction = {
  easeIn: 'cubic-bezier(0.755, 0.050, 0.855, 0.060)',
  easeOut: 'cubic-bezier(0.230, 1.000, 0.320, 1.000)',
  easeInOut: 'cubic-bezier(0.860, 0.000, 0.070, 1.000)',
};

export const timings = {
  smaller: '0.2s',
  small: '0.3s',
  large: '0.4s',
  larger: '0.6s',
};

export const animations = {
  moveUpAndFadeIn: {
    desktop: `moveUpAndFadeIn ${timings.larger} ${transitionFunction.easeIn}`,
    mobile: `moveUpAndFadeIn ${timings.large} ${transitionFunction.easeIn}`,
  },
  moveUpAndFadeOut: {
    desktop: `moveUpAndFadeOut ${timings.larger} ${transitionFunction.easeOut}`,
    mobile: `moveUpAndFadeOut ${timings.large} ${transitionFunction.easeOut}`,
  },
  moveDownAndFadeIn: {
    desktop: `moveDownAndFadeIn ${timings.larger} ${transitionFunction.easeIn}`,
    mobile: `moveDownAndFadeIn ${timings.large} ${transitionFunction.easeIn}`,
  },
  moveDownAndFadeOut: {
    desktop: `moveDownAndFadeOut ${timings.larger} ${transitionFunction.easeOut}`,
    mobile: `moveDownAndFadeOut ${timings.large} ${transitionFunction.easeOut}`,
  },
  moveDownAndFadeInTop: {
    desktop: `moveDownAndFadeInTop ${timings.larger} ${transitionFunction.easeIn}`,
    mobile: `moveDownAndFadeInTop ${timings.large} ${transitionFunction.easeIn}`,
  },
  scaleUpAndFadeIn: {
    desktop: `scaleUpAndFadeIn ${timings.larger} ${transitionFunction.easeInOut}`,
    mobile: `scaleUpAndFadeIn ${timings.large} ${transitionFunction.easeInOut}`,
  },
  scaleDownAndFadeOut: {
    desktop: `scaleDownAndFadeOut ${timings.larger} ${transitionFunction.easeOut}`,
    mobile: `scaleDownAndFadeOut ${timings.large} ${transitionFunction.easeOut}`,
  },
  modalOpening: {
    desktop: `modalOpening ${timings.larger} ${transitionFunction.easeInOut}`,
    mobile: `modalOpening ${timings.large} ${transitionFunction.easeInOut}`,
  },
  modalClosing: {
    desktop: `modalClosing ${timings.larger} ${transitionFunction.easeOut}`,
    mobile: `modalClosing ${timings.large} ${transitionFunction.easeOut}`,
  },
  fadeOut: `fadeOut ${timings.small} ${transitionFunction.easeOut}`,
  fadeIn: `fadeIn ${timings.small} ${transitionFunction.easeOut}`,
  fadeOutMobileDesktop: {
    desktop: `fadeOut ${timings.larger} ${transitionFunction.easeOut}`,
    mobile: `fadeOut ${timings.large} ${transitionFunction.easeOut}`,
  },
  pulse: 'pulse 1.2s ease infinite',
};

export const motionTypes = {
  expand: {
    up: `all ${timings.small} ${transitionFunction.easeInOut}`,
    down: `all ${timings.smaller} ${transitionFunction.easeOut}`,
    properties: 'height, width, max-width, max-height, min-width, max-width',
  },

  fade: {
    in: `opacity ${timings.small} ${transitionFunction.easeOut}`,
    out: `opacity ${timings.small} ${transitionFunction.easeOut}`,
  },

  elevate: {
    up: `all ${timings.small} ${transitionFunction.easeOut}`,
    down: `all ${timings.smaller} ${transitionFunction.easeOut}`,
    properties: 'box-shadow , border-color ',
  },

  moveLarge: {
    in: `transform ${timings.larger} ${transitionFunction.easeOut}`,
    out: `transform ${timings.larger} ${transitionFunction.easeOut}`,
  },

  moveSmall: {
    in: `transform ${timings.small} ${transitionFunction.easeOut}`,
    out: `transform ${timings.small} ${transitionFunction.easeOut}`,
  },

  scale: {
    up: `transform ${timings.small} ${transitionFunction.easeInOut}`,
    down: `transform ${timings.smaller} ${transitionFunction.easeOut}`,
  },

  moveUpAndFade: {
    in: `all ${timings.large} ${transitionFunction.easeOut}`,
    out: `all ${timings.small} ${transitionFunction.easeOut}`,
    properties: 'opacity, transform',
  },

  moveDownAndFade: {
    in: `all ${timings.large} ${transitionFunction.easeOut}`,
    out: `all ${timings.small} ${transitionFunction.easeOut}`,
    properties: 'opacity, transform',
  },

  moveAndScale: {
    desktop: `transform ${timings.larger} ${transitionFunction.easeInOut}`,
    mobile: `transform ${timings.large} ${transitionFunction.easeInOut}`,
  },

  colorTransition: `all ${timings.large} ${transitionFunction.easeOut}`,

  margin: {
    bottom: 'margin-bottom 200ms ' + transitionFunction.easeOut,
  },
};

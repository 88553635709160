import get from "lodash/get";
import isEmpty from "lodash/isEmpty";
import Head from "next/head";
import { useCallback } from "react";
import {
  BlogObjectSchema,
  FaqObjectSchema,
  VideoObjectSchema,
} from "services/schema.service";

export enum HeaderTemplates {
  Default = "default",
  Second = "second",
  Stepper = "stepper"
}

export type HeaderSchemaProps = {
  data: SchemaTypes[];
};

export type SchemaTypes =
  | FaqObjectSchema
  | BlogObjectSchema
  | VideoObjectSchema;

export const HeaderSchema: React.FC<HeaderSchemaProps> = (props) => {
  const schema = get(props, "data", null) as SchemaTypes[];

  const removeHtmlTags = useCallback((inputString: string): string => {
    return inputString.replace(/<[^>]*>?/g, "")?.replace(/&nbsp;/g, " ");
  }, []);

  return (
    <>
      {!isEmpty(schema) && (
        <Head>
          <script
            type="application/ld+json"
            dangerouslySetInnerHTML={{
              __html: removeHtmlTags(JSON.stringify(schema)),
            }}
          />
        </Head>
      )}
    </>
  );
};

import styled from "styled-components";
import { MegaMenuProps } from "./MegaMenu.types";
import React, { useMemo } from "react";
import { Grid, Tab, Tabs } from "@material-ui/core";
import { TabContext, TabPanel } from "@material-ui/lab";
import { fwdColors } from "config/fwd-colors";
import { Link } from "shared-components/Link";
import { Icon } from "shared-components/Icon";
import { motionTypes } from "components/Timeline/motion";

const Wrapper = styled.div`
  width: 1184px;
  background-color: ${fwdColors.white};

  .MuiTabs-root {
    .MuiTab-root {
      max-width: 100%;
      padding: 17.5px 24px;
      border-bottom: 1px solid ${fwdColors.grey};
      opacity: 1;

      &.Mui-selected {
        color: ${fwdColors.orange};
        border-bottom: 1px solid transparent;
        background: ${fwdColors.orange5};
      }

      .MuiTab-wrapper {
        align-items: flex-start;
        text-transform: none;
      }
    }

    .MuiTabs-indicator {
      left: 0;
      width: 6px;
      background-color: ${fwdColors.orange};
    }
  }
`;

const ContentGrid = styled(Grid)`
  background-color: ${fwdColors.orange5};

  .MuiTabPanel-root {
    padding: 32px;
    padding-top: 16px;
  }
`;

const TypeName = styled(Link)`
  font-size: 20px;
  font-weight: 450;
  line-height: 30px;
  color: ${fwdColors.darkGreen};
  display: flex;
  gap: 8px;
`;

const HoverableLink = styled(Link)`
  border-radius: 8px;
  transition: ${motionTypes.colorTransition};

  :hover {
    background-color: ${fwdColors.orange20};
  }
`;

const LinkItem = styled(HoverableLink)`
  font-weight: 700;
  line-height: 24px;
  display: flex;
  gap: 10px;
  align-items: flex-start;
  padding: 8px;
`;

const LinkItemWithoutUrl = styled.div`
  color: ${fwdColors.greyDarkest};
  margin-top: 12px;
  margin-bottom: 4px;
  font-size: 14px;
  font-weight: 450;
  line-height: 21px;
`;

const LinksContainer = styled(Grid)<{ isLast: boolean }>`
  display: flex;
  flex-direction: column;

  > ${TypeName} + :not(${LinkItemWithoutUrl}) {
    margin-top: 16px;
  }

  ${(p) =>
    !p.isLast
      ? `
    padding-right: 32px;
  `
      : `
      border-left: 1px solid ${fwdColors.grey};

    padding-left: 32px;

  `}
`;

const TabLabel = styled.div`
  display: flex;
  gap: 10px;
  align-items: flex-start;
  font-weight: 700;
  font-size: 16px;
  text-align: left;
`;

const CtaButtonContainer = styled(Grid)`
  display: flex;
  justify-content: flex-end;
  min-height: 32px;

  &.MuiGrid-item {
    margin-bottom: 12px;
  }
`;

const CtaButton = styled(HoverableLink)`
  color: ${fwdColors.orange};
  padding: 4px 12px;
  font-weight: 700;
`;

const GTM_COMPONENT_NAME = "MegaMenu";

export const MegaMenuWithType: React.FC<MegaMenuProps> = ({
  groupWithTypes,
}) => {
  const [activeTabId, setActiveTabId] = React.useState("1");

  const { tabs, tabContents } = useMemo(() => {
    if (!groupWithTypes) {
      return { tabs: [], tabContents: [] };
    }

    const result = {
      tabs: groupWithTypes.map((group) => ({
        label: group.label,
        tabId: group.label,
        icon: group.icon,
      })),
      tabContents: groupWithTypes.map((group) => ({
        tabId: group.label,
        types: group.types,
        ctaButton: {
          label: group.cta_button.label,
          url: group.cta_button.url,
          openInNewTab: group.cta_button.open_in_new_tab,
          gaEventLabel: group.cta_button.ga_event_label,
        },
      })),
    };

    setActiveTabId(result.tabs[0].tabId);

    return result;
  }, [groupWithTypes]);

  const handleChange = (event, newValue) => {
    setActiveTabId(newValue);
  };

  return (
    <Wrapper>
      <TabContext value={activeTabId}>
        <Grid container>
          <Grid item xs={3}>
            <Tabs
              orientation="vertical"
              variant="scrollable"
              value={activeTabId}
              onChange={handleChange}
            >
              {tabs.map((tab) => (
                <Tab
                  onMouseEnter={() => {
                    setActiveTabId(tab.tabId);
                  }}
                  label={
                    <TabLabel>
                      <Icon
                        icon={tab.icon}
                        color={fwdColors.orange}
                        size={24}
                      />
                      {tab.label}
                    </TabLabel>
                  }
                  value={tab.tabId}
                />
              ))}
            </Tabs>
          </Grid>
          <ContentGrid item xs={9}>
            {tabContents.map(({ ctaButton, ...tabContent }) => (
              <TabPanel value={tabContent.tabId}>
                <Grid container>
                  <CtaButtonContainer item xs={12}>
                    <CtaButton
                      href={ctaButton.url}
                      gtmComponentName={GTM_COMPONENT_NAME}
                      openInNewTab={ctaButton.openInNewTab}
                      gaEventLabel={ctaButton.gaEventLabel || ctaButton.label}
                    >
                      {ctaButton.label}
                    </CtaButton>
                  </CtaButtonContainer>
                  {tabContent.types.map((type, index) => (
                    <LinksContainer
                      isLast={
                        tabContent.types.length > 1 &&
                        index === tabContent.types.length - 1
                      }
                      item
                      xs={6}
                    >
                      <TypeName href={type.url}>
                        {type.label}
                        <Icon
                          size={24}
                          color={fwdColors.orange}
                          icon={type.icon || "chevron-right"}
                        />
                      </TypeName>
                      {type.items.map((item) => {
                        const hasLink = !!item.url;

                        if (!hasLink) {
                          return (
                            <LinkItemWithoutUrl>
                              {item.label}
                            </LinkItemWithoutUrl>
                          );
                        }

                        return (
                          <LinkItem
                            href={item.url}
                            openInNewTab={item.open_in_new_tab}
                          >
                            <Icon
                              icon={item.icon}
                              color={fwdColors.orange}
                              size={24}
                            />
                            {item.label}
                          </LinkItem>
                        );
                      })}
                    </LinksContainer>
                  ))}
                </Grid>
              </TabPanel>
            ))}
          </ContentGrid>
        </Grid>
      </TabContext>
    </Wrapper>
  );
};

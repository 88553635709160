import styled from "styled-components";
import { MegaMenuProps } from "./MegaMenu.types";
import React from "react";
import { fwdColors } from "config/fwd-colors";
import { Link } from "shared-components/Link";
import chunk from "lodash/chunk";
import { BrandIcon } from "components/BrandIcon";

const Wrapper = styled.div`
  background-color: ${fwdColors.white};
`;

const MenuGroup = styled.div<{ cols: number; firstCols: number }>`
  ${(p) => `grid-template-columns: ${p.firstCols <= 4 ? "320px" : "641px"} ${'340px '.repeat(Math.max(0, p.cols - 1)).trim()}`};
  padding: 32px 0px 32px 32px;
  display: grid;
  max-height: 610px;
  overflow: auto;
`;

const MenuGroupColumn = styled.div`
  margin-right: 24px;
  padding-right: 0;
  border-right: 1px solid #ccc;
  &:last-child {
    border-right: 0;
  }
`;

const MenuGroupColumnLeft = styled.div`
  display: grid;
  gap: 16px 32px;
  grid-template-columns: 312px 270px;
`;

const MenuGroupColumnTypeSub = styled.div`
  gap: 16px;
`;

const ProductTitle = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
  margin-bottom: 16px;
`;

const MenuType = styled.div`
  gap: 4px;
`;

const TypeLink = styled(Link)`
  display: flex;
  gap: 4px;
  padding: 4px;
  width: fit-content;
  border-radius: 4px;

  &:hover {
    background-color: #fbe6d8;
  }
`;

const TypeText = styled.div`
  display: flex;
  gap: 4px;
  padding: 4px;
  width: fit-content;
  border-radius: 4px;
  .MuiSvgIcon-root {
    width: 24px; 
    height: 24px;
    path {
      fill: #e87722!important;
    }
  }
`;


const TypeLabel = styled.div`
  font-size: 20px;
  font-weight: bold;
  line-height: 25px;
`;


const TypeItems = styled.div`
  padding-left: 20px;
`;

const TypeItemLink = styled(Link)`
  padding: 4px 4px;
  width: fit-content;
  border-radius: 4px;
  &:hover {
    background-color: #fbe6d8;
  }
`;

const HighlighTag = styled.span`
  background-color: #e87722;
  color: #fff;
  padding: 1px 3px;
  display: inline-block;
  margin-left: 8px;
  border-radius: 4px;
  font-size: 10px;
`;

const MenuGroupColumnTypeSubC = (props) => {
  const { c } = props;
  return (
    <MenuGroupColumnTypeSub className="d-flex flex-column gap-16">
      {c.map((type, idx) => (
        <MenuType className="d-flex flex-column">
          {type?.url ? (<TypeLink href={type?.url} openInNewTab={type?.open_in_new_tab}>
            {type?.icon && <BrandIcon
              icon={type?.icon || ""}
              width="24px"
              height="24px"
            />}
            <TypeLabel>
              {type?.label}
            </TypeLabel>
          </TypeLink>) : (<TypeText>
            {type?.icon && <BrandIcon
              icon={type?.icon || ""}
              width="24px"
              height="24px"
            />}
            <TypeLabel>
              {type?.label}
            </TypeLabel>
          </TypeText>)}

          {type.items && type.items.length > 0 && (
            <TypeItems>
              {type.items?.map((item) => (
                <TypeItemLink href={item?.url} openInNewTab={item?.open_in_new_tab}>
                  {item.label}
                  {item.highlight_tags && <HighlighTag>{item.highlight_tags}</HighlighTag>}
                </TypeItemLink>
              ))}
            </TypeItems>
          )}
        </MenuType>
      ))}
    </MenuGroupColumnTypeSub>
  );
};

export const MegaMenuWithTypeColumnUI: React.FC<MegaMenuProps> = ({
  groupWithTypes,
}) => {
  return (
    <Wrapper>
      <MenuGroup cols={groupWithTypes?.length || 0} firstCols={groupWithTypes?.[0]?.types?.length || 0}>
        {groupWithTypes?.map((col, idx) => (
          <MenuGroupColumn>
            <ProductTitle>{col.label}</ProductTitle>
            {idx === 0 && (<MenuGroupColumnLeft>
              {chunk(col.types, 4)?.map((c, idx) => (
                <MenuGroupColumnTypeSubC c={c} key={idx} />
              ))}
            </MenuGroupColumnLeft>)}
            {
              idx > 0 && (
                <>
                  {chunk(col.types, 4)?.map((c) => (
                    <MenuGroupColumnTypeSubC c={c} key={idx} />
                  ))}</>
              )
            }
          </MenuGroupColumn>
        ))}
      </MenuGroup>
    </Wrapper>
  );
};

import {
  breakpoint,
  colorsUtils,
  DynamicDiv,
  Text,
  TextLink,
} from "@d2c-ui-components-react";
import { gtmUltils } from "@utils";
import { useScrollWidth } from "hooks/useScroll";
import I18nContext from "i18n/context/LanguageContext";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import { ILink } from "types/Navigation";
import { gaEventAction } from "utils/gtm";
import { resolveAbsoluteUrl } from "utils/route";
import { MegaMenuProps } from "./MegaMenu.types";

const textLinkProps = {
  icon: "arrow-right" as const,
  styles: {
    container: {
      fontWeight: "700",
      lineHeight: "24px",
      fontSize: "16px",
      padding: "8px",
      marginLeft: "-4px",
    },
  },
};

const actionLinkProps = {
  styles: { container: { color: colorsUtils.fwdOrange } },
};

const Heading = styled(DynamicDiv)`
  display: flex;
  justify-content: space-between;
  button {
    div {
      white-space: nowrap;
    }
  }
`;

const MainSection = styled.div<{ isFirefox: boolean }>`
  position: relative;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: 800px;

  ${breakpoint("lg")`
    height: 640px;
  `}

  ${breakpoint("xxl")`
    height: 480px;
  `}
`;

const GroupDiv = styled.div`
  border-left: 1px solid ${colorsUtils.secondaryColorFwdGrey};
  padding: 0 24px;
  width: 300px;
  flex: 1;

  .textWithIcon {
    font-weight: bold;
    color: ${colorsUtils.darkGreen};
  }

  svg {
    margin-right: 8px;
  }
`;

const MenuLink = styled.a`
  &:hover {
    color: ${colorsUtils.darkGreen};
  }
`;

const Wrapper = styled.div<{ width: number }>`
  position: relative;
  display: block;
  width: 100%;
  background: ${colorsUtils.fwdWhite};
  padding: 24px 12px;
  padding-left: 0;
  overflow: auto;
  scroll-behavior: smooth;

`;

export const MegaMenuWithoutType: React.FC<MegaMenuProps> = ({
  groups,
  isShow = false,
}) => {
  const i18nContext = useContext(I18nContext);
  const wrapperRef = useRef<HTMLDivElement>(null);
  const mainRef = useRef<HTMLDivElement>(null);

  const wrapperWidth = useScrollWidth(wrapperRef, isShow);
  const [isFirefox, setIsFirefox] = useState<boolean>(false);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const isFirefox = window.navigator.userAgent
        .toLowerCase()
        .includes("firefox");

      if (isFirefox) {
        setIsFirefox(true);
        if (isShow && wrapperWidth > 0) {
          const megaMenuEl = document.getElementById("mega-menu");
          if (megaMenuEl) {
            megaMenuEl.style.maxWidth = wrapperWidth + "px";
            megaMenuEl.style.width = "100%";
         
          }
        }
        
      }
    }

  }, [isShow, wrapperWidth]);

  const renderLinks = useCallback(
    (links: ILink[]) =>
      links.map((link: ILink, i) => {
        const handleOnClick = () => {
          gtmUltils.buttonClick({
            buttonTitle: link.title,
            destinationUrl: resolveAbsoluteUrl(link.url, i18nContext),
            componentName: "MegaMenu",
          });
          gtmUltils.navigation({
            event_action: gaEventAction.button_click,
            buttonTitle: link.title,
            destination_url: resolveAbsoluteUrl(link.url, i18nContext),
            component_name: "MegaMenu",
            product_affiliation:
              i18nContext.componentConfigs?.gaPageData?.product_affiliation ||
              "",
          });
        };

        return (
          <MenuLink
            key={i}
            title={link.title}
            href={resolveAbsoluteUrl(link.url, i18nContext)}
            target={link.openInNewTab ? "_blank" : "_self"}
          >
            <TextLink {...textLinkProps} onClick={handleOnClick}>
              {link.title}
            </TextLink>
          </MenuLink>
        );
      }),
    [i18nContext]
  );

  return (
    <Wrapper ref={wrapperRef} width={wrapperWidth}>
      <MainSection isFirefox={isFirefox} ref={mainRef}>
        {groups.map((group) => (
          <GroupDiv key={group?.title}>
            <Heading>
              <Text xsFontSize={"1.25rem"} lineHeight="30px" fontWeight="300">
                {group?.title}
              </Text>
              <MenuLink
                title={group?.title}
                href={resolveAbsoluteUrl(group?.url, i18nContext)}
                target={group?.openInNewTab ? "_blank" : "_self"}
              >
                <TextLink {...actionLinkProps}>
                  {group?.referenceButtonLabel ?? ""}
                </TextLink>
              </MenuLink>
            </Heading>
            {group?.items && renderLinks(group.items)}
          </GroupDiv>
        ))}
      </MainSection>
    </Wrapper>
  );
};
